import React from "react"
import styled, { css } from "styled-components"
import ProductSubImage from "./ProductSubImage"
import { media } from "../../../../styles/media"

const StyledContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  ${() =>
    media.sp(css`
      width: 100%;
    `)}
`

export default function ProductSubImageBlock({
  mainImage,
  subImages,
  onclick,
}) {
  return (
    <StyledContainer>
      <ProductSubImage imgSrcset={mainImage} onclick={onclick} />

      {/* Render sub images */}
      {subImages.length > 0 &&
        subImages.map(subImage => {
          const {
            id,
            localFile: {
              childImageSharp: { fluid },
            },
          } = subImage

          // render
          return (
            <ProductSubImage key={id} imgSrcset={fluid} onclick={onclick} />
          )
        })}
    </StyledContainer>
  )
}
