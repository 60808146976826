import React from "react"
import styled, { css } from "styled-components"
import { media } from "../../../styles/media"

const StyledDiv = styled.div`
  margin-top: 30px;
  width: 85%;
  ${() =>
    media.sp(css`
      width: 100%;
    `)}
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 10px;
  row-gap: 10px;
`

const StyledIndexName = styled.h3`
  font-weight: bold;
  font-size: 1.12em;
  ${() =>
    media.sp(css`
      text-decoration: underline;
    `)}
`

const StyledAllergenList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  li {
    margin-right: 20px;
    ${() =>
      media.sp(css`
        font-size: 0.9em;
      `)}
  }
`

export default function Allergen({ allergenList }) {
  return (
    <StyledDiv>
      <StyledIndexName>アレルゲン</StyledIndexName>
      <StyledAllergenList>{allergenList}</StyledAllergenList>
    </StyledDiv>
  )
}
