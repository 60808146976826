import React from "react"
import styled, { css } from "styled-components"
import { media } from "../../../styles/media"

const StyledBackgroundImage = styled.div`
  position: absolute;
  bottom: 100px;
  left: 0;
  width: 100%;
  z-index: -1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  opacity: 0.1;
  img {
    width: 50%;
  }
  ${() =>
    media.sp(css`
      bottom: 50vh;
      img {
        width: 80%;
      }
    `)}
`
export default function BackgroundImage({ imageSrc }) {
  return (
    <StyledBackgroundImage>
      <img src={imageSrc} alt="" />
    </StyledBackgroundImage>
  )
}
