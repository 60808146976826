import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"

const InnerPI = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
  }
  p {
    font-size: 0.9em;
  }
`

export default function ProductMainImage({ imgSrcset }) {
  return (
    <InnerPI>
      <Image fluid={imgSrcset} />
    </InnerPI>
  )
}
