import React from "react"
import styled, { css } from "styled-components"
import ProductMainImage from "./ProductMainImage"
import ProductSubImageBlock from "./ProductSubImageBlock"
import Notification from "./Notification"
import { media } from "../../../../styles/media"

const StyledContainer = styled.div`
  width: 35%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  p {
    font-size: 0.8em;
    padding-top: 1.62em;
  }
  ${() =>
    media.sp(css`
      width: 100%;
      margin-bottom: 50px;
      .gatsby-image-wrapper {
        width: 75%;
      }
    `)}
`
export default function ProductImage({ mainImage, subImages }) {
  const [activeImage, setActiveImage] = React.useState(mainImage)
  return (
    <StyledContainer>
      <ProductMainImage imgSrcset={activeImage} />
      <ProductSubImageBlock
        mainImage={mainImage}
        subImages={subImages}
        onclick={setActiveImage}
      />
      <Notification />
    </StyledContainer>
  )
}
