import React from "react"
import styled, { css } from "styled-components"
import { media } from "../../../styles/media"
import Ingredient from "./Ingredient"
import WithParenthesesIngredient from "./WithParenthesesIngredient"
const StyledDiv = styled.div`
  margin-top: 30px;
  width: 85%;
  ${() =>
    media.sp(css`
      width: 100%;
    `)}
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 10px;
  row-gap: 10px;
`

const RowFlex = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const StyledIndexName = styled.h3`
  font-weight: bold;
  font-size: 1.12em;
  ${() =>
    media.sp(css`
      text-decoration: underline;
    `)}
`

const StyledNotificationText = styled.span`
  font-size: 0.9em;
`

const StyledDetailContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  row-gap: 2px;
  column-gap: 15px;
`

const StyledIngredientRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 15px;
  line-height: 1.5em;
`

export default function Ingredients({ displayIngredients, ingredients }) {
  const {
    energy,
    protein,
    fat,
    saturatedFattyAcid,
    transFattyAcid,
    cholesterol,
    carbohydrate,
    carbohydrateSugar,
    fiber,
    sodium,
    unitName,
  } = ingredients

  return (
    <StyledDiv>
      <RowFlex>
        <StyledIndexName>成分</StyledIndexName>
        <StyledNotificationText>
          <span>{displayIngredients && displayIngredients}</span>( {unitName} )
        </StyledNotificationText>
      </RowFlex>
      <StyledDetailContainer>
        <StyledIngredientRow>
          <Ingredient name="エネルギー" value={energy} />
          <Ingredient name="たんぱく質" value={protein} />
        </StyledIngredientRow>
        <StyledIngredientRow>
          <WithParenthesesIngredient
            name="脂質"
            value={fat}
            subs={[
              { name: "飽和脂肪酸", value: saturatedFattyAcid },
              { name: "トランス脂肪酸", value: transFattyAcid },
            ]}
          />
        </StyledIngredientRow>
        <StyledIngredientRow>
          <Ingredient name="コレステロール" value={cholesterol} />
        </StyledIngredientRow>
        <StyledIngredientRow>
          <WithParenthesesIngredient
            name="炭水化物"
            value={carbohydrate}
            subs={[
              { name: "糖質", value: carbohydrateSugar },
              { name: "食物繊維", value: fiber },
            ]}
          />
        </StyledIngredientRow>
        <StyledIngredientRow>
          <Ingredient name="食塩相当量" value={sodium} />
        </StyledIngredientRow>
      </StyledDetailContainer>
    </StyledDiv>
  )
}
