import React from "react"
import styled, { css } from "styled-components"
import { media } from "../../../styles/media"
import ProductLinksForTemp from "./ProductLinksForTemp"
import CatchPhrase from "./CatchPhrase"
import InfoLink from "./InfoLink"
import Note from "./Note"
import { ProductImage } from "./ProductImage"
import Title from "./Title"
import Allergen from "./Allergen"
import Ingredients from "./Ingredients"
import BackgroundImage from "./BackgroundImage"

const StyledProductContents = styled.div`
  z-index: 9;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`

const StyledProductDetailContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.primary.brown};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.brown};
  padding: 0px 0 30px;
`

const StyledProductTextInfo = styled.div`
  width: 60%;
  order: 1;
  ${() =>
    media.sp(css`
      width: 100%;
    `)}
  .pd0 {
    border-top: 1px solid ${({ theme }) => theme.colors.primary.brown};
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary.brown};
    padding: 0px 0 30px;
  }
`
export default function Product({
  location,
  brandLogoImgSrcset,
  productName,
  mainImageSrcset,
  subImages,
  catchPhrase,
  displayIngredients,
  ingredients,
  allergenList,
  bgImageSrc,
  note,
}) {
  return (
    <>
      <Title
        brandLogoImgSrcset={brandLogoImgSrcset}
        productName={productName}
      />
      <StyledProductContents>
        <ProductImage mainImage={mainImageSrcset} subImages={subImages} />
        <StyledProductTextInfo>
          <CatchPhrase catchPhrase={catchPhrase} />
          <StyledProductDetailContainer>
            <Allergen allergenList={allergenList} />
            <Ingredients
              displayIngredients={displayIngredients}
              ingredients={ingredients}
            />
          </StyledProductDetailContainer>
          <Note html={note} />
          <InfoLink />
        </StyledProductTextInfo>
      </StyledProductContents>
      <BackgroundImage
        imageSrc={bgImageSrc}
        alt="barley image for background"
      />
      <ProductLinksForTemp location={location} />
    </>
  )
}
