import React from "react"
import Image from "gatsby-image"
import styled, { css } from "styled-components"
import { media } from "../../../styles/media"

const StyledContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 0 20px;
  .brandIcon {
    display: block;
    width: 120px;
  }
  .productName {
    padding: 0 20px;
    h2 {
      font-size: 1.62em;
      line-height: 1.62em;
      ${() =>
        media.sp(css`
          line-height: 1.1em;
        `)}
    }
  }
  z-index: 9;
`
export default function Title({ brandLogoImgSrcset, productName }) {
  return (
    <StyledContainer>
      <div className="brandIcon">
        {brandLogoImgSrcset && <Image fluid={brandLogoImgSrcset} />}
      </div>
      <div className="productName">
        <h2>{productName}</h2>
      </div>
    </StyledContainer>
  )
}
