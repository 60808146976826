import React from "react"
import styled from "styled-components"

const StyledText = styled.p`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`

export default function Ingredient({ name, value }) {
  if (!value) return null
  return (
    <StyledText>
      {name} : {value}
    </StyledText>
  )
}
