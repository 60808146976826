import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"

const StyledSubImage = styled.div`
  width: 23%;
  .gatsby-image-wrapper {
    width: 100%;
  }
  :hover {
    cursor: pointer;
  }
`

const ProductSubImage = ({ id, imgSrcset, onclick }) => {
  return (
    <StyledSubImage key={id} onClick={() => onclick(imgSrcset)}>
      <Image fluid={imgSrcset} />
    </StyledSubImage>
  )
}

export default ProductSubImage
