import React from "react"
import styled from "styled-components"

const StyledFlexText = styled.p`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`

const StyledMark = styled.span`
  display: inline-block;
  margin-inline: 4px;
`

const StyledInnerMark = styled.span`
  display: inline-flex;
  column-gap: 8px;
`

export default function WithParenthesesIngredient({ name, value, subs }) {
  if (!value) return null

  const parentheses = {
    forward: "(",
    backward: ")",
  }
  if (subs.some(sub => sub.value)) {
    return (
      <StyledFlexText>
        <span>
          {name} : {value}
        </span>
        <StyledMark>{parentheses.forward}</StyledMark>
        <StyledInnerMark>
          {subs.map(sub => (
            <span key={sub.name}>
              {sub.name} : {sub.value}
            </span>
          ))}
        </StyledInnerMark>

        <StyledMark>{parentheses.backward}</StyledMark>
      </StyledFlexText>
    )
  }

  return (
    <p>
      {name} : {value}
    </p>
  )
}
