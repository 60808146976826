import React from "react"
import { graphql } from "gatsby"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import { Product } from "../features/product-template"
import BarleyImg from "../images/barley-francois.svg"

import Layout from "../components/Layout"

const ProductWrap = styled.div`
  background: ${({ theme }) => theme.colors.light.brown};
  padding: 100px 5% 100px;
  position: relative;
  z-index: 9;
  ${() =>
    media.sp(css`
      padding: 70px 5% 100px;
    `)}
`

export default function ProductTemplate({ data, location }) {
  const { product } = data
  const foodAllergensAsc = product.foodAllergens.sort(
    (a, b) => a.displayNum - b.displayNum
  )
  const foodAllergens = foodAllergensAsc.map(food => {
    const key = food.id
    const jpNotation = food.japaneseNotation
    return (
      <li key={key}>
        <p>{jpNotation}</p>
      </li>
    )
  })

  const conditionalBrandLogoImgSrcset = () => {
    if (product.brand.slug === "naturel") {
      return product.brand.subLogo1.childImageSharp.fluid
    }
    return product.brand.defaultLogo.childImageSharp.fluid
  }

  // Render page
  return (
    <Layout>
      <ProductWrap>
        <Product
          location={location}
          brandLogoImgSrcset={conditionalBrandLogoImgSrcset()}
          productName={product.name}
          mainImageSrcset={product.mainImage.childImageSharp.fluid}
          subImages={product.subImages}
          catchPhrase={product.catchPhrase}
          displayIngredients={product.displayIngredients}
          ingredients={product.ingredients}
          allergenList={foodAllergens}
          bgImageSrc={BarleyImg}
          note={product.note}
        />
      </ProductWrap>
    </Layout>
  )
}

export const query = graphql`
  query ProductQuery($slug: String) {
    product: strapiProducts(slug: { eq: $slug }) {
      brand {
        name
        slug
        defaultLogo {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        subLogo1 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      catchPhrase
      excerpt
      displayIngredients
      foodAllergens {
        id
        displayNum
        englishNotation
        japaneseNotation
      }
      ingredients {
        carbohydrate
        carbohydrateSugar
        cholesterol
        energy
        fat
        fiber
        protein
        saturatedFattyAcid
        sodium
        unitName
        transFattyAcid
      }
      mainImage {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      name
      note
      slug
      subImages {
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      uncompromisingApproaches {
        id
        key
        notation
      }
    }
  }
`
