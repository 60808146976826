import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { media } from "../../../styles/media"

const StyledContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  ${() =>
    media.sp(css`
      flex-flow: row wrap;
      justify-content: flex-start;
      a {
        width: 100%;
        text-align: right;
      }
    `)}
  p {
    padding-right: 20px;
    color: ${({ theme }) => theme.colors.primary.brown};
  }
  a {
    color: ${({ theme }) => theme.colors.primary.green};
  }
`

export default function InfoLink() {
  return (
    <StyledContainer>
      <p>製造した工場を知りたい方はこちら</p>
      <Link to="/inquiry/faq-page/">（製造所固有記号についてのQ＆A）</Link>
    </StyledContainer>
  )
}
