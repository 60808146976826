import React from "react"
import styled from "styled-components"

export const StyledNote = styled.div`
  padding: 30px 0;
  ul {
    margin-bottom: 20px;
    list-style: square;
    padding-left: 20px;
    li {
      font-size: 0.9em;
    }
  }
`
export default function Note({ html }) {
  return <StyledNote dangerouslySetInnerHTML={{ __html: html }} />
}
