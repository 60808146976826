import React from "react"
import styled from "styled-components"

const StyledText = styled.p`
  padding: 0 0 30px;
  font-size: 0.9em;
`

export default function CatchPhrase({ catchPhrase }) {
  return <StyledText>{catchPhrase}</StyledText>
}
