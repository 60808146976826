import React from "react"
import styled from "styled-components"
import { BsArrowRightShort } from "react-icons/bs"
import { IconContext } from "react-icons"
import {
  LinkButton,
  LinkTextWithArrow,
  BackLinkButton,
} from "../../../components/Link"
// import { HistoryBackButton, BackLink } from "../../../components/Button"

const ArrowIconComponent = () => (
  <IconContext.Provider value={{ color: "white", size: "1.62em" }}>
    <BsArrowRightShort />
  </IconContext.Provider>
)

const UnderItems = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  column-gap: 6rem;
  /* padding: 50px; */
  /* margin: 50px auto; */
  margin-block-start: 50px;
  @media only screen and (max-width: 1000px) {
    justify-content: space-around;
    column-gap: 0;
  }
  @media only screen and (max-width: 600px) {
    margin-inline: auto;
    flex-flow: column nowrap;
    max-width: 300px;
    align-items: stretch;
    row-gap: 1rem;
  }
`

function ConditionalButton({ pathname }) {
  switch (true) {
    case pathname.startsWith("/deli/"):
      return <BackLinkButton to="/deli" text="もどる" />
    case pathname.startsWith("/sweets-sweets/"):
      return <BackLinkButton to="/sweets-sweets" text="もどる" />
    case pathname.startsWith("/hitokuchido/"):
      return <BackLinkButton to="/hitokuchido" text="もどる" />
    default:
      return (
        <LinkTextWithArrow
          href="/approach/commitment"
          text="パンづくりへのこだわり"
        />
      )
  }
}

const ProductLinksForTemp = ({ location }) => {
  return (
    <UnderItems>
      <ConditionalButton pathname={location.pathname} />
      <LinkButton
        to="/product-search/"
        text="商品検索"
        iconComponent={<ArrowIconComponent />}
      />
      <LinkButton
        to="/inquiry/"
        text="商品に関するお問合せ"
        iconComponent={<ArrowIconComponent />}
      />
    </UnderItems>
  )
}

export default ProductLinksForTemp
